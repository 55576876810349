$( document ).ready(() => {
  $( 'button[data-href]').each((i, element) => {
    const targetUrl = $( element ).attr('data-href');

    if (!_.isNil(targetUrl)) {
      $( element ).on('click', (event) => {
        event.preventDefault();

        window.open(targetUrl);

        return false;
      });
    }
  });
});
