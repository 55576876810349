var wrapperDom = require('./wrapper.html');

class ConsoleLinkFieldBuilder {
  constructor(target, includeOpenButton, includeTitle) {
    this.target = $( target );
    this.targetUrl = this.target.val();
    if (_.isNil(includeOpenButton))
    {
      this.includeOpenButton = true;
    }
    else
    {
      this.includeOpenButton = includeOpenButton;
    }

    if (_.isNil(includeTitle))
    {
      this.includeTitle = false;
    }
    else
    {
      this.includeTitle = includeTitle;
    }
  }

  setup() {
    this.dom = $(wrapperDom);
    if (this.includeTitle) {
      this.dom.find(".input-group-text").text(this.target.data("title"));
      this.target.clone().insertAfter(this.dom.find(".input-group-prepend"));
    }
    else {
      this.dom.find(".input-group-prepend").remove();
      this.dom.prepend(this.target.clone());
    }

    this.openButton = this.dom.find("a.open-button");
    this.copyButton = this.dom.find("a.copy-button");

    this.target.replaceWith(this.dom);

    if (_.isNil(this.targetUrl) || this.targetUrl === '') {
      this.openButton.remove();
      this.copyButton.remove();
      return;
    }

    if (this.includeOpenButton)
    {
      this.openButton.attr('href', this.targetUrl);
    }
    else
    {
      this.openButton.remove();
    }

    this.copyButton.attr('href', this.targetUrl);

    var text = 'Le lien a été copié';
    if (!this.includeOpenButton)
    {
      text = 'Le contenu a été copié';
    }
    this.copyButton.popover({
      content: text,
      placement: 'top',
      trigger: 'manual'
    });

    this.copyButton.on('click', (e) => {
      e.preventDefault();

      navigator.clipboard.writeText(this.targetUrl).then(() => {
        this.copyButton.popover('show');
        setTimeout(() => {
          this.copyButton.popover('hide');
        }, 5000);
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });

      return true;
    });
  }
}

$(document).ready(() => {
  $( "input.console-link-field" ).each((i, target) => {
    var builder = new ConsoleLinkFieldBuilder(target, true, false);
    builder.setup();
  });
  $( "input.console-link-field-with-title" ).each((i, target) => {
    var builder = new ConsoleLinkFieldBuilder(target, true, true);
    builder.setup();
  });

  $( "input.console-copy-field" ).each((i, target) => {
    var builder = new ConsoleLinkFieldBuilder(target, false, false);
    builder.setup();
  });
  $( "input.console-copy-field-with-title" ).each((i, target) => {
    var builder = new ConsoleLinkFieldBuilder(target, false, true);
    builder.setup();
  });
});
