require("./style.scss");
var iframeDom = require("./iframe.html");

class YoutubeLoader {
  constructor(target) {
    this.target = $( target );
  }

  setup() {
    this.target;
    this.ytId = this.target.data('ytid');
    this.iframe = $( iframeDom );
    this.iframe.attr('src', `https://www.youtube-nocookie.com/embed/${this.ytId}?rel=0&autoplay=1`);

    this.target.append("<span class=\"yt-play-button\"></span>");
    this.target.off('click').on('click', (e) => {
      e.preventDefault();

      this.target.html(this.iframe);

      return false;
    });
  }
}

$( document ).ready((e) => {
  var loaders = $('a.yt-video').map((i, e) => {
    var loader = new YoutubeLoader(e);
    loader.setup();
    return loader;
  });

});
