import Rails from "@rails/ujs"

import "./sentry.js"
import './tools';
import './link_field';

Rails.start()

import 'bootstrap';
import ahoy from "ahoy.js";

window.jQuery = $;
window.$ = $;
window.Popper = Popper;

require('../src/youtube_loader');

$( document ).ready(() => {
  $( 'button.close.lm_close[data-target]' ).each((i, e) => {
    $( e ).on('click', (ev) => {
      var dataTarget = $(e).attr('data-target');
      $( dataTarget ).remove();
    });
  });
});
