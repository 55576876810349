// Don't forget to require images
require('../images/home/leadmatick-white-small.png');
require('../images/home/bg-white-building.jpg');

require('../images/home/icon-realestate.png');
require('../images/home/icon-floorplan.png');
require('../images/home/icon-handy.png');

require('../images/home/usecase-physical.jpg');
require('../images/home/usecase-visio.jpg');
require('../images/home/usecase-autonomous.jpg');

require('../images/home/icon-analytics.png');
